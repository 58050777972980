<template>
  <div class="wrap xa-cell">
    <el-breadcrumb class="xa-flex">
      <el-breadcrumb-item
        class="link"
        v-if="parent && parent.path && parent.title"
        :to="parent.path"
        >{{ parent.title }}</el-breadcrumb-item
      >
      <el-breadcrumb-item
        class="link"
        v-for="(item, index) in parents"
        :key="index"
        :to="item.path"
        >{{ item.title }}</el-breadcrumb-item
      >
      <el-breadcrumb-item> {{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <slot name="right"></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: '标题',
      type: String
    },
    parents: {
      type: Array,
      default() {
        return []
      }
    },
    parent: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  min-height: 40px;
}
</style>
