import Mock from 'mockjs'
const {
  Random
} = Mock
let logoUrl = "https://agri-private.static.xag.cn/v3/upload/e1f39c04227ecfebd0eb3e14fdfd7a51.jpeg?imageView2/0/w/100/h/100&e=1645001044&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:MTdcgKnjSlY8scFl-sJAM1H4MSM="
function getMockObj () {
  return {
    guid: Random.id(),
    logo: "F354FE51BBDC7209E25C8E773ADA20DB",
    logo_url: logoUrl,
    name: Random.first()
  }
}
export let mockCategroyData = [
  {
    ...getMockObj(),
    son: [
      {
        ...getMockObj(),
        son: [
          {
            ...getMockObj(),
            son: [
              {
                ...getMockObj(),
                son: [
                  {
                    ...getMockObj(),
                  }
                ]
              },
              {
                ...getMockObj(),
                son: [
                  {
                    ...getMockObj(),
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        guid: Random.id(),
        logo: "F354FE51BBDC7209E25C8E773ADA20DB",
        logo_url: logoUrl,
        name: Random.first(),
      }
    ]
  },
  {
    ...getMockObj()
  }

]