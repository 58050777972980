<template>
<el-table :data="tableData" style="width: 100%;"  :class="{'isChild':isChild}">
  <el-table-column v-if="!isHightLevel" type="expand">
      <template slot-scope="props">
        <categoryTable :tableData="props.row.son" :numberStrArr="numberStrArr" :isChild="true" :parentIndex="props.$index" :level="level + 1" :upGuid="props.row.guid" @add="onAddNew" @edit="onEdit" @delete="onDelte" @move="onMove"/>
      </template>
  </el-table-column>
  <el-table-column label="序号" width="100">
     <template slot-scope="scope">
       <div> {{scope.row.$index}} </div>
     </template>
  </el-table-column>
  <el-table-column label="示例图" width="180">
    <template slot-scope="scope">
      <div @click="onPriview(scope.row.logo_url)" class="img-wrap" :style="{'backgroundImage':'url('+scope.row.logo_url+')'}"></div>
    </template>
  </el-table-column>
  <el-table-column label="分类名称" prop="name" width="180"></el-table-column>
  <el-table-column label="操作">
    <template slot-scope="scope">
      <el-button size="mini" @click="onEdit(scope.row)">编辑</el-button>
      <el-button v-if="!isHightLevel" size="mini" @click="onAddNew(scope.row)">{{addNewString}}</el-button>
      <el-button v-if="scope.$index!==0" size="mini" @click="onMove(scope.row,'up',scope.$index)">上移</el-button>
      <el-button v-if="scope.$index!==tableData.length-1" size="mini" @click="onMove(scope.row,'down',scope.$index)">下移</el-button>
      <el-button size="mini" type="danger" @click="onDelte(scope.row)">删除</el-button>
    </template>
  </el-table-column>
</el-table>
</template>
<script>
export default {
  name: 'categoryTable',
  props: {
    tableData: Array,
    parentIndex: Number,
    isChild: {
      type: Boolean,
      default: false
    },
    upGuid: String,
    level: {
      type: Number,
      default: 1
    },
    numberStrArr: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    isHightLevel () {
      return this.level === 5
    },
    addNewString () {
      return `新增${this.numberStrArr[this.level]}级`
    }
  },
  methods: {
    onAddNew(data) {
      this.$emit('add', {guid: data.guid, level: data.level})
    },
    onEdit(data) {
      this.$emit('edit', {
        ...data,
        // upGuid: this.upGuid,
        isEdit: true
      })
    },
    onDelte(data) {
      this.$emit('delete', data)
    },
    onPriview(img) {
      this.$preViewImg({ isShow: true, imgSrc: img, imgList: [img] })
    },
    onMove(data, action, order) {
      if (action === undefined) {
        this.$emit('move', data)
      } else {
        let guid_two = this.tableData[action === 'up' ? order - 1 : order + 1].guid
        this.$emit('move', {
          guid: data.guid,
          upGuid: this.upGuid,
          oldOrder: order,
          newOrder: action === 'up' ? order - 1 : order + 1,
          guid_one: data.guid, // 点击按钮的那一项的guid
          guid_two // 需要转移的那一项的guid
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.img-wrap {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border: 1px dotted #ccc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
/deep/ .el-table.isChild {
  background-color: #f2f2f2;
  .el-table__header-wrapper {
    display: none;
  }
}
/deep/ .el-table::before {
  content: "";
  display: none;
}
/deep/ .el-table__expanded-cell {
  padding: 4px 0 8px 50px;
}
/deep/ .el-table__row:last-of-type td {
  border: none;
}
</style>
