<template>
  <el-container class="category-page xa-container">
    <el-header height="initial">
      <TitleHead title="分类管理">
        <div class="content-head xa-cell" slot="right">
          <el-button
            @click="onHandleCategory({level: 0})"
            type="primary"
            icon="el-icon-plus"
            size="small"
            >新建分类</el-button
          >
          <div class="search-box">
            <el-input
              placeholder="请输入内容"
              v-on:keyup.enter.native="onSearchQuery"
              v-model="query.keyword"
              size="small"
              ><i
                @click="onSearchQuery"
                slot="prefix"
                :class="{ 'xa-color-info': !!query.keyword }"
                class="el-input__icon el-icon-search"
              ></i
              ><i
                v-show="query.keyword"
                @click="query.keyword = ''"
                slot="suffix"
                class="el-input__icon el-icon-close"
              ></i
            ></el-input>
          </div>
        </div>
      </TitleHead>
    </el-header>
    <el-main v-loading="isLoading">
      <categoryTable
        v-if="initTable"
        :tableData="tableData"
        @add="onCategoryAdd"
        @move="onCategoryMove"
        @edit="onCategoryEdit"
        @delete="onCategoryDelete"
        :numberStrArr="numberStrArr"
      />
    </el-main>
    <el-dialog
      :title="formDialog.title"
      :visible.sync="formDialog.show"
      width="50%"
    >
      <categoryForm 
        v-if="formDialog.show"
        :oldData="formDialog.data"
        @submit="onFormSubmit"
      />
    </el-dialog>
  </el-container>
</template>
<script>
import TitleHead from '@/components/comHead/TitleHead'
import { getCategoryList, deleteCategory, moveCategoryOrder } from '@target/apis/mall'
import categoryTable from './categoryTable' 
import { mapState } from 'vuex'
import { mockCategroyData } from './mock.js'
export default {
  components: {
    TitleHead,
    categoryTable,
    categoryForm: () => import('./categoryForm')
  },
  data() {
    return {
      formDialog: {
        title: '编辑一级分类',
        show: false,
        data: null
      },
      isLoading: true,
      initTable: false,
      query: {
        status: 1,
        keyword: '',
        page_index: 1,
        page_size: 20
      },
      tableData: [],
      numberStrArr: ['一', '二', '三', '四', '五']
    }
  },
  computed: {
    ...mapState(['windowChangeCount'])
  },
  watch: {
    windowChangeCount() {
      this.initTable = false
      this.$nextTick(() => {
        this.initTable = true
      })
    }
  },
  methods: {
    async onCategoryMove(data) {
      const params = {
        guid_one: data.guid_one,
        guid_two: data.guid_two
      }
      const loading = this.$loading()
      try {
        await this.$actionWithConfirm(moveCategoryOrder(params))
        this.$message.success('操作成功')
        this.initData()
      } catch (error) {
        throw error
      } finally {
        loading.close()
      }
    },
    onCategoryAdd(data) {
      this.onHandleCategory(data)
    },
    onCategoryEdit(data) {
      this.onHandleCategory(data)
    },
    async onCategoryDelete(data) {
      await this.$confirm(
        '此操作将删除该分类，如果有子分类，也将一并删除, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      const loading = this.$loading()
      try {
        await this.$actionWithConfirm(deleteCategory(data.guid))
        this.$message.success('删除成功')
        this.initData()
      } catch (error) {
        throw error
      } finally {
        loading.close()
      }
    },
    onHandleCategory(data = { level: 1 }) {
      console.log('data', data)
      const isEdit = data.isEdit
      let title = `${isEdit ? '编辑' : '新增'}${this.numberStrArr[data.level - ( isEdit ? 1 : 0)]}级分类`
      let fromData = {
        ...data,
        guid: isEdit ? data.guid : undefined,
        upGuid: isEdit ? undefined : data.guid
      }
      this.formDialog.title = title
      this.formDialog.show = true
      this.formDialog.data = fromData
    },
    onSearchQuery() {
      this.initData()
    },
    onFormSubmit() {
      this.formDialog.show = false
      this.initData()
    },
    formatCagegoryData (arr, option = {}) {
      const prefix = option.prefix || ''
      const level = option.level || 1
      const upGuid = option.upGuid
      arr.forEach((item, index) => {
        item.$index = prefix + (index + 1)
        item.level = level
        item.upGuid = upGuid
        if (item.son && item.son.length >0) {
          this.formatCagegoryData(item.son, {
            prefix: item.$index + ' - ',
            level: level + 1,
            upGuid: item.guid
          })
        }
      })
      return arr
    },
    async initData() {
      try {
        this.isLoading = true
        this.tableData = await this.$actionWithConfirm( getCategoryList(this.query) )
        this.tableData = this.formatCagegoryData(this.tableData)
        // this.tableData = this.formatCagegoryData(mockCategroyData)
        console.log('this.tableData', this.tableData)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
      this.initTable = true
    }
  },
  mounted() {
    this.initData()
  }
}
</script>
<style lang="scss" scoped>
.category-page {
  /deep/ .el-header {
    padding: 0;
  }
  /deep/ .el-main {
    padding: 0;
  }
}
.content-head {
  .search-box {
    margin-left: 16px;
  }
}
</style>
